<router>
{
    name: 'Flow Analytics',
}
</router>
<template lang="pug">

v-container.mt-8
    v-row(justify='center')
        v-col.d-flex.flex-wrap
            .text-h4.brand--text Flow Analytics
    v-row()
        v-col(justify='left', cols='8')
            FlowChart(
                name='Current Day',
                :seriesData="charts['Today']"
            )
        v-spacer
        v-col(cols='3')
            v-card(light)
                v-card-title "Times to Meet Demand"
                v-card-text
    v-row
        v-col(cols='6')
            FlowChart(
                name='2 Days Ago',
                :seriesData="charts['2 Days Ago']"
            )
        v-col(cols='6')
            FlowChart(
                name='Yesterday',
                :seriesData="charts['Yesterday']"
            )
    v-row
        v-col(cols='6')
            FlowChart(
                name='Tomorrow',
                :seriesData="charts['Tomorrow']"
            )
        v-col(cols='6')
            FlowChart(
                name='2 Days From Today',
                :seriesData="charts['2 Days From Today']"
            )
    v-row
        v-col(cols='6')
            FlowChart(
                name='3 Days From Today',
                :seriesData="charts['3 Days From Today']"
            )
        v-col(cols='6')
            FlowChart(
                name='4 Days From Today',
                :seriesData="charts['4 Days From Today']"
            )


</template>

<script lang="js">

import FlowChart from '@/components/portal/analytics/FlowChart';
import moment from 'moment';

export default {
    // extends: dashboard,
    components: {
        FlowChart,
    },
    meta: {
    },
    data () {
        return {
            charts: {},
            selectedCustomer: null,
            chartNames: [
                '2 Days Ago', 'Yesterday', 'Today', 'Tomorrow', '2 Days From Today', '3 Days From Today', '4 Days From Today'
            ],
            dataSources: [
                'Production', 'Demand', 'Storage', 'Consumption'
            ],
            customerId: null,
        };
    },
    watch: {
        customerId: {
            handler (newVal, oldVal) {
                if (newVal == oldVal) return;
                this.getData();
            },
            immediate: true,
        }
    },
    computed: {
        currentCustomerId () {
            return this.$store.state.customerId;
        }
    },

    methods: {
        init () {
            this.getData();
        },

        getData () {
            // fetch data from API or websocket
            this.customerId = this.currentCustomerId;
            console.log('getData fired',this.customerId);
            const now = moment('2023-06-01');
            this.socketEmit('getFlowData', { customerId: 74, dateStart: now.format('YYYY-MM-DD')  }, result => {
                if(result.error){
                    console.error('Failed to fetch data:', result.error);
                    return;
                }
                this.chartNames.forEach(name => {
                    // data should be an array of SeriesData, {name: string, data: SeriesRow[]}[]
                    const flowDataGrid = result.data.find(d => d.name === name);
                    if (!flowDataGrid) {
                        console.warn(`No data found for chart ${name}`);
                        return;
                    }
                    const chartData = [];
                    // const xAxis = seriesData.data.map(d => d.hour);
                    // need to convert the data columns into array of objects { name: string, data: number}[]
                    this.dataSources.forEach(source => {
                        const columnData = flowDataGrid.data.map(r => r[source.toLowerCase()] );
                        const series = {name: source, data: columnData };
                        chartData.push(series);
                    });
                    this.$set(this.charts, name, chartData);
                });
            });

        },
    },
    mounted () {
        this.init();
        console.log('FlowAnalytics mounted');
    },
    created () {
        this.$store.dispatch('connectSocket');
    }
};
</script>

<style scoped>
</style>
